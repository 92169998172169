import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConnectService } from '../../services/index';

@Component({
  selector: 'activate',
  templateUrl: './activate.component.html',
  styleUrls: [
      '../../app.component.css'
    ]
})
export class ActivateLoginComponent implements OnInit {

  error: string;
  success: string;
  redirection: string;

  constructor(
    private route: ActivatedRoute,
    public pwService: ConnectService
  ) { }

  ngOnInit() {
  
    this.route.queryParams.subscribe((params) => {
        if (params.key === undefined || params.key === '') {
          this.success = null;
          this.error = 'ERROR';
          return;
        }
        if (params.redirect === undefined || params.redirect === '') {
          this.success = null;
          this.error = 'ERROR';
          return;
        }
        if (params.guid === undefined || params.guid === '') {
          this.success = null;
          this.error = 'ERROR';
          return;
        }
        if (params.auth === undefined || params.auth === '') {
          this.success = null;
          this.error = 'ERROR';
          return;
        }
        const profile = { appguid: params['guid'], appid:  params['auth'], activationKey: params['key'] }
        this.pwService.activatelogin(profile).subscribe((res) => {
            this.redirection = params.redirect;
            this.error = null;
            this.success = 'OK';
        }, () => {
            this.success = null;
            this.error = 'ERROR';
        });
    });

  }

  redirect() {
    window.open(this.redirection);
  }

}
