import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConnectService } from '../../services/index';

@Component({
  selector: 'activate',
  templateUrl: './activate.component.html',
  styleUrls: [
      '../../app.component.css'
    ]
})
export class ActivateComponent implements OnInit {

  error: string;
  success: string;
  redirection: string;

  constructor(
    private route: ActivatedRoute,
    public pwService: ConnectService
  ) { }

  ngOnInit() {
  
    this.route.queryParams.subscribe((params) => {
        this.pwService.activate(params['key']).subscribe((res) => {
          console.log(res)
            this.redirection = res;
            this.error = null;
            this.success = 'OK';
        }, () => {
            this.success = null;
            this.error = 'ERROR';
        });
    });

  }

  redirect() {
    window.open(this.redirection);
  }

  visitUdo() {
    window.open("https://udo.tools");
  }

 

}
