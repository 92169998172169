import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConnectService } from '../../services/index';

@Component({
  selector: 'reset-init',
  templateUrl: './reset-init.component.html',
  styleUrls: [
      '../../app.component.css'
    ]
})
export class ResetInitComponent implements OnInit {

  public error = null;
  public mail = '';
  public mailerror = null;
  public isCreated = false;



  constructor(
    private route: ActivatedRoute,
    public pwService: ConnectService
  ) { }

  ngOnInit() {

  }

  signup() {
    this.raz();
    if (this.mail === '') {
      this.mailerror = 'Vous devez saisir une adresse mail';
      return;
    }
    if (!this.pwService.isValidMailFormat(this.mail)) {
      this.mailerror = 'Adresse email non valide';
      return
    }
    this.pwService.resetInit(this.mail).subscribe((res: any) => {
      this.isCreated = true;
    }, (e) => {
      if (e.error === 'User is not found in database') {
        this.mailerror = "Cette adresse mail n'existe pas"
      }
      if (e.error === 'Error while saving user in database') {
        this.error = "Une erreur est survenue lors de la création du lien de récupération"
      }
    });
  }

  
  raz() {
    this.error = null;
    this.mailerror = null;
  }
 

}
