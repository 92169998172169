import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_URL } from '../app.constants';
import { map } from 'rxjs/operators';


@Injectable()
export class ConnectService {

    constructor(
        private http: HttpClient
    ) { }

    private resourceUrl = `${API_URL}api`;

    private colors = ['rgb(191,38,0)', 'rgb(255,86,48)', 'rgb(255,171,0)', 'rgb(54,179,126)', 'rgb(54,179,126)'];  

    save(account: any): Observable<any> {
        const copy: any = Object.assign({ }, account);
        return this.http.post(`${this.resourceUrl}/register/profile`, copy);
    }

    activate(key: string): any {
        return this.http.post(`${this.resourceUrl}/activate`, key, { responseType: 'text' });
    }

    activatelogin(profile: any): any {
        return this.http.post(`${this.resourceUrl}/activatelogin`, profile, { responseType: 'text' });
    }

    resetInit(mail: string) {
        return this.http.post(`${this.resourceUrl}/account/reset_password/init`, mail, { responseType: 'text' });
    }

    resetFinish(keyAndPassword: any) {
        return this.http.post(`${this.resourceUrl}/account/reset_password/finish`, keyAndPassword, { responseType: 'text' });
    }

    resetFinishLogin(keyAndPassword: any, env: string) {
        if (env === 'dev') {
            return this.http.post(`${this.resourceUrl}/resetpwdlogin/finish`, keyAndPassword, { responseType: 'text' });
        } else {
            return this.http.post(`${this.resourceUrl}/resetpwdlogin/finishprod`, keyAndPassword, { responseType: 'text' });
        }
    }

    setPassword(keyAndPassword: any) {
        return this.http.post(`${this.resourceUrl}/account/set_password`, keyAndPassword, { responseType: 'text' });
    }

    getAppName(url: string) {
        return this.http.post(`${this.resourceUrl}/application/urlname`, url, { responseType: 'text' });
    }

    public measureStrength(pass: string): any {  
        let score = 0;  
        // award every unique letter until 5 repetitions  
        let letters = {};  
        for (let i = 0; i< pass.length; i++) {  
        letters[pass[i]] = (letters[pass[i]] || 0) + 1;  
        score += 5.0 / letters[pass[i]];  
        }  
        // bonus points for mixing it up  
        let variations = {  
        digits: /\d/.test(pass),  
        lower: /[a-z]/.test(pass),  
        upper: /[A-Z]/.test(pass),  
        nonWords: /\W/.test(pass),  
        };  
    
        let variationCount = 0;  
        for (let check in variations) {  
        variationCount += (variations[check]) ? 1 : 0;  
        }  
        score += (variationCount - 1) * 10;  
        return Math.trunc(score);  
    }

    public getColor(score: number): any {  
        let idx = 0;  
        if (score > 90) {  
          idx = 4;  
        } else if (score > 70) {  
          idx = 3;  
        } else if (score >= 40) {  
          idx = 2;  
        } else if (score >= 20) {  
          idx = 1;  
        }  
        return {  
          idx: idx + 1,  
          col: this.colors[idx]  
        };  
    }

    public getStatus(password): string {
        const score = this.measureStrength(password);
        if (password.length < 8) {
            return 'Le mot de passe doit comporter au moins 8 caractères.'
        }
        if (score > 90) {  
            return 'Très fort'  
        } else if (score > 70) {  
            return 'Fort' 
        } else if (score >= 40) {  
            return 'Bonne'
        } else if (score >= 20) {  
            return 'Acceptable'; 
        } 
    }

    public isValidMailFormat(email:string){
        let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

        if (email != "" && (email.length <= 5 || !EMAIL_REGEXP.test(email))) {
            return false;
        }

        return true;
    }

    public getUserFromCredentials(email, username, firstName, lastName, password, appguid) {
        return {email, login: username, firstName, lastName, password, appguid};

    }

}