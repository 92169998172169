import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConnectService } from '../../services/index';

@Component({
  selector: 'set-password',
  templateUrl: './set-password.component.html',
  styleUrls: [
      '../../app.component.css'
    ]
})
export class SetPasswordComponent implements OnInit {

  public error = null;
  public keyerror = false;
  public key = null
  public password: string = '';
  public passwordconfirm: string = '';
  public passwordvisible = false;
  public passwordconfirmvisible = false;
  public isCreated = false;
  public pwError = null;
  public pwconfirmError = null;
  success: string;
  redirection: string;

  bar0: string;  
  bar1: string;  
  bar2: string;  
  bar3: string;  
  bar4: string; 


  constructor(
    private route: ActivatedRoute,
    public pwService: ConnectService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params['key'] === undefined || params['key'] === '') {
        this.keyerror = true;
      } else {
        this.key = params['key'];
      }
  });
  }

  reset() {
    this.raz();
    if (this.password === '' || this.password.length < 8) {
      this.pwError = 'Le mot de passe n\'est pas conforme';
      return;
    }
    if ((this.password !== this.passwordconfirm)) {
      this.pwconfirmError = 'Les mots de passe de correspondent pas';
      return;
    }
    this.pwService.setPassword({key: this.key, newPassword: this.password}).subscribe((res) => {
        this.redirection = res;
        this.error = null;
        this.success = 'OK';
    }, (e) => {
        this.success = null;
        if (e.error === 'User is not found in database') {
          this.error = "Erreur lors de la récupération : le compte associé est introuvable"
        }
        if (e.error === 'Error while saving user in database') {
          this.error = "Une erreur est survenue lors de la récupération"
        }
    });
  }

  redirect() {
    window.open(this.redirection);
  }

  visitUdo() {
    window.open("https://udo.tools");
  }
  
  raz() {
    this.error = null;
    this.pwError = null;
    this.pwconfirmError = null;
  }

  getColors($event) {
    this.setBarColors(5, 'rgb(223, 225, 230)')
    if ($event !== '') {
      const c = this.pwService.getColor(this.pwService.measureStrength($event));
      this.setBarColors(c.idx, c.col)
    } else {
      console.log("nothing")
    }
  }

  private setBarColors(count, col) {  
    for (let _n = 0; _n < count; _n++) {  
        this['bar' + _n] = col;  
    }  
  } 
 

}
