import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConnectService } from '../../services/index';

@Component({
  selector: 'reset-finish',
  templateUrl: './reset-finish.component.html',
  styleUrls: [
      '../../app.component.css'
    ]
})
export class ResetFinishLoginComponent implements OnInit {

  public error = null;
  public keyerror = false;
  public key = null;
  public params;
  public password: string = '';
  public passwordconfirm: string = '';
  public passwordvisible = false;
  public passwordconfirmvisible = false;
  public isCreated = false;
  public pwError = null;
  public pwconfirmError = null;
  private env = '';

  bar0: string;  
  bar1: string;  
  bar2: string;  
  bar3: string;  
  bar4: string; 


  constructor(
    private route: ActivatedRoute,
    public pwService: ConnectService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params.key === undefined || params.key === '') {
        this.keyerror = true;
        return;
      }
      if (params.redirect === undefined || params.redirect === '') {
        this.keyerror = true;
        return;
      }
      if (params.guid === undefined || params.guid === '') {
        this.keyerror = true;
        return;
      }
      if (params.auth === undefined || params.auth === '') {
        this.keyerror = true;
        return;
      }
      this.params = params;
    });
  }

  reset() {
    this.raz();
    if (this.password === '') {
      this.pwconfirmError = 'Mot de passe non valide';
      return;
    }
    if ((this.password !== this.passwordconfirm)) {
      this.pwconfirmError = 'Les mots de passe de correspondent pas';
      return;
    }
    let env = '';
    if (this.params.env === undefined) {
      env = 'dev';
    } else {
      env = this.params.env;
    }
    const profile = { appguid: this.params['guid'], appid: this.params['auth'], resetKey: this.params['key'], password: this.password }
    this.pwService.resetFinishLogin(profile, env).subscribe((res: any) => {
      this.isCreated = true;
    }, (e) => {
      if (e.error === 'User is not found in database') {
        this.error = "Erreur lors de la récupération : le compte associé est introuvable";
        return;
      }
      this.error = "Une erreur est survenue lors de la récupération";
    });
  }

  redirect() {
    window.open(this.params['redirect']);
  }
  
  raz() {
    this.error = null;
    this.pwError = null;
    this.pwconfirmError = null;
  }

  getColors($event) {
    this.setBarColors(5, 'rgb(223, 225, 230)')
    if ($event !== '') {
      const c = this.pwService.getColor(this.pwService.measureStrength($event));
      this.setBarColors(c.idx, c.col)
    } else {
      console.log("nothing")
    }
  }

  private setBarColors(count, col) {  
    for (let _n = 0; _n < count; _n++) {  
        this['bar' + _n] = col;  
    }  
  } 
 

}
