import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignupComponent } from './pages/signup/signup.component';
import { ActivateComponent } from './pages/activate/activate.component';
import { ActivateLoginComponent } from './pages/activatelogin/activate.component';
import { ResetInitComponent } from './pages/reset/reset-init.component';
import { ResetFinishComponent } from './pages/reset/reset-finish.component';
import { ResetFinishLoginComponent } from './pages/resetlogin/reset-finish.component';
import { SetPasswordComponent } from './pages/set/set-password.component';

const routes: Routes = [
  
{ path: '', component: SignupComponent },
{ path: 'signup', component: SignupComponent },
{ path: 'activate', component: ActivateComponent },
{ path: 'resetpassword', component: ResetInitComponent },
{ path: 'changepasswordapp', component: ResetFinishLoginComponent },
{ path: 'changepassword', component: ResetFinishComponent },
{ path: 'setpassword', component: SetPasswordComponent },
{ path: 'activateapp', component: ActivateLoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
