import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConnectService } from '../../services/index';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'signup',
  templateUrl: './signup.component.html',
  styleUrls: [
      '../../app.component.css'
    ]
})
export class SignupComponent implements OnInit {

  public mail: string = '';
  public password: string = '';
  public passwordconfirm: string = '';
  public firstname: string = '';
  public familyname: string = '';
  public rememberMe: any;
  public appGuid = null;
  public isloginerror = false;
  public passwordvisible = false;
  public passwordconfirmvisible = false;
  public error = null;
  public mailerror = null;
  public firstnameError = null;
  public familynameError = null;
  public pwError = null;
  public pwconfirmError = null;
  public isCreated = false;
  public appName: string = 'UDo';
  public loading = false;
  public conditionsAccepted = false;

  bar0: string;  
  bar1: string;  
  bar2: string;  
  bar3: string;  
  bar4: string; 

  constructor(
    private route: ActivatedRoute,
    public pwService: ConnectService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const guid = params['app'];
      if (guid !== undefined) {
        this.appGuid = guid;
        this.pwService.getAppName(guid).subscribe((res) => {
          this.appName = res;
        }, (e) => {
          console.log(e)
        });
      }
    });
  }

  signup() {
    if (this.loading) {
      return;
    }
    this.raz();
    if (this.mail === '') {
      this.mailerror = 'Vous devez saisir une adresse mail';
      return;
    }
    if (!this.pwService.isValidMailFormat(this.mail)) {
      this.mailerror = 'Adresse email non valide';
      return;
    }
    if (this.familyname === '') {
      this.familynameError = 'Vous devez saisir un nom';
      return;
    }
    if (this.firstname=== '') {
      this.firstnameError = 'Vous devez saisir un prénom';
      return;
    }
    if (this.password === '' || this.password.length < 8) {
      this.pwError = 'Le mot de passe n\'est pas conforme';
      return;
    }
    if ((this.password !== this.passwordconfirm)) {
      this.pwconfirmError = 'Les mots de passe de correspondent pas';
      return;
    }
    if (!this.conditionsAccepted) {
      this.error = 'Vous devez accepter les conditions d\'utilisation';
      return;
    }
    const account = this.pwService.getUserFromCredentials(this.mail, this.mail, this.firstname, this.familyname, this.password, this.appGuid);
    this.loading = true;
    this.pwService.save(account).subscribe((res: any) => {
      this.isCreated = true;
      this.loading = false;
    }, (e) => {
      this.loading = false;
      console.log(e.error)
      if (e.error === 'User already exist' || e.error === 'User already exist : ') {
        this.mailerror = "Cette adresse mail existe déjà"
      }
      if (e.error === 'Email already exist' || e.error === 'Email already exist : ') {
        this.mailerror = "Cette adresse mail existe déjà"
      }
      if (e.error === 'Error while saving user in database' || e.error === 'Error while saving user in database : ') {
        this.error = "Une erreur est survenue lors de la création de votre compte"
      }
      if (e.error === 'Error while sending email : ') {
        this.error = "Une erreur est survenue lors de l'envoi du lien d'activation, merci de réessayer ultérieurement ou changez votre adresse mail"
      }
    });
  }

  raz() {
    this.error = null;
    this.mailerror = null;
    this.firstnameError = null;
    this.familynameError = null;
    this.pwError = null;
    this.pwconfirmError = null;
  }

  getColors($event) {
    this.setBarColors(5, 'rgb(223, 225, 230)')
    if ($event !== '') {
      const c = this.pwService.getColor(this.pwService.measureStrength($event));
      this.setBarColors(c.idx, c.col)
    } else {
      console.log("nothing")
    }
  }

  private setBarColors(count, col) {  
    for (let _n = 0; _n < count; _n++) {  
        this['bar' + _n] = col;  
    }  
  }  


}
