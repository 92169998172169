import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { SignupComponent } from './pages/signup/signup.component';
import { ActivateComponent } from './pages/activate/activate.component';
import { ActivateLoginComponent } from './pages/activatelogin/activate.component';
import { ResetInitComponent } from './pages/reset/reset-init.component';
import { ResetFinishComponent } from './pages/reset/reset-finish.component';
import { ResetFinishLoginComponent } from './pages/resetlogin/reset-finish.component';
import { SetPasswordComponent } from './pages/set/set-password.component';
import { ConnectService } from './services/index';

@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    ActivateComponent,
    ActivateLoginComponent,
    ResetInitComponent,
    ResetFinishComponent,
    SetPasswordComponent,
    ResetFinishLoginComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    FormsModule,
    CommonModule,
    HttpClientModule
  ],
  providers: [
    ConnectService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
